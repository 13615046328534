export default {
  address: '高雄市鳳山區鳳捷路 & 環河街',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1841.3753429954274!2d120.3861762!3d22.6257834!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e1b09771a9585%3A0xb7e86afd528069e5!2zODMw6auY6ZuE5biC6bOz5bGx5Y2A6bOz5o236LevICYg55Kw5rKz6KGX!5e0!3m2!1szh-TW!2stw!4v1577892786904!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/p7g29VTdQDmUAwhT6',
  phone: '07-7027555',
  fbLink: 'https://www.facebook.com/%E4%BA%AC%E5%9F%8E%E9%B3%B3%E7%BF%94-111948606978067/',
  fbMessage: 'https://m.me/111948606978067',
  caseName: '京城鳳翔',
  indigatorLength: 10,

  houseInfos: [
    ['建設公司', '京成建設股份有限公司'],
    ['建築設計', '林子森林伯諭聯合建築師事務所'],
    ['營造團隊', '百鋐營造股份有限公司'],
    ['廣告代銷', '上揚國際建築團隊'],
    ['建照執造', '(108)高市工建築字第01080號'],
    ['公設比例', '35.8%'],
    ['樓層規劃', '地上12層、地下2層'],
    ['規劃戶數', '住家154戶、店面6戶'],
    ['坪數規劃', '25坪(2房)、34坪(3房)、39坪(店面)'],
    ['衛浴設備', 'ROCA智能馬桶、V&B面盆、INAX淋浴組'],
  ],

  gtmCode: ['WNB9V3S'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '預約賞屋',
    subTitle: '請留下您的大名、電話、進行預賞屋!'
  }
}
